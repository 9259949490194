.slick-dots {
    li {
        button:before {
            color: white;
            opacity: 1;
        }
    }
    li.slick-active {
        button:before {
            color: #33bcdb;
            opacity: 1;
        }
    }
}
